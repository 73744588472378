import React, { Component } from 'react';

import { connect }          from 'react-redux';

import { 
    mdiMapMarkerRemove,
}                           from '@mdi/js';

import Icon                 from '@mdi/react';
import ReactGA              from "react-ga4";

import { 
    getRoute,
    updateRoute,
    updateRouteActivity
}                           from '../../../redux/routes/details/actions';

import Page                 from '../../../components/page/Page';
import Map                  from '../../../components/maps/Map';
import Marker               from '../../../components/maps/Marker';
import ErrorState           from '../../../components/state/ErrorState';
import LoadingState         from '../../../components/state/LoadingState';
import Tabs                 from '../../../components/tabs/Tabs';
import Tab                  from '../../../components/tabs/Tab';

import Dashboard            from '../../../lib/api/Dashboard';
import {
    dateFormat,
    convertLicenseplate
}                           from '../../../lib/utils/Utils';

import Activity             from './activity/Activity';

import { 
    getSortedActivities 
}                           from '../../../redux/routes/details/selectors';

import CrossynPath          from './crossyn/CrossynPath';
import CrossynVehicle       from './crossyn/CrossynVehicle';

import Stops                from './stops/Stops';
import StopList             from './stops/StopList';

import Behaviour from './behaviour/Behaviour';

import Crossyn from '../../../lib/crossyn/Crossyn';

import CrossynCoordinates from './crossyn/CrossynCoordinates';

import './Details.css';

class Details extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            followDriver: false, 
        };

        this.markers = {}
        this.map = null;


    }

    componentDidMount() {
        const { dispatch, match: { params } } = this.props;

        if (params.route_id) {
    
            dispatch(getRoute(params.route_id)).then((action) => {
                const { data: { data }, error } = action || {};
      
                if (data) {
        
                    const route_id = data.route_id;
                    this.dashboard_route_updated = Dashboard.subscribe('/route/updated', { route_id: route_id }, (data) => {
                        if (route_id === data.route_id) {
                            dispatch(updateRoute(data));
                        }
                    });
           
                    this.dashboard_activity_updated = Dashboard.subscribe('/activity/updated', { route_id: route_id }, (data) => {
                        if (route_id === data.route_id) {
                            dispatch(updateRouteActivity(data));
                        }
                    });
                }
            });

        }

    }

    componentWillUnmount() {

        if (this.dashboard_route_updated) {
            this.dashboard_route_updated.remove();
        }

        if (this.dashboard_activity_updated) {
            this.dashboard_activity_updated.remove();
        }

    }

    focusMarker = (activity_id) => {
        if (this.markers[activity_id]) {
            this.markers[activity_id].focus();
        }
    }

    renderInfo = () => {
        const { followDriver } = this.state;
        const { data: { number, extra_data, driver, driver_name, createdAt, branche, licenseplate }, vehicle=null, behavior } = this.props;

        return (
            <div className="card">
                <div className="route_info_header">
                    <h2 style={ {margin:0, padding:20} }>Route informatie</h2>
                </div>

                <div className="route_info">
                    <div className="line">
                        <div className="label">Route: </div>
                        <div className="value">{ number }</div>
                    </div>
                    <div className="line">
                        <div className="label">Chauffeur: </div>
                        <div className="value">{ driver_name }</div>
                    </div>
                    {
                        (licenseplate)
                        &&
                        <div className="line">
                            <div className="label">Kenteken: </div>
                            <div className="value">{ licenseplate } { (vehicle === null) && <Icon path={mdiMapMarkerRemove} size={'1.3rem'} color={'#c60000'}/> }</div>
                        </div>
                    }
                    <div className="line">
                        <div className="label">Aangemaakt op: </div>
                        <div className="value">{ dateFormat(new Date(createdAt), false) }</div>
                    </div>
                    <div className="route_actions">
                        <div className="line">
                            <div className="label">Volg de chauffeur</div>
                            <div className="value">
                                <div className="switch">
                                    <input
                                        type="checkbox"
                                        id="followDriver"
                                        name="followDriver"
                                        checked={followDriver}
                                        onChange={() => {
                                            this.setState({
                                                followDriver: !followDriver
                                            });
                                        }}
                                    />
                                    <label htmlFor="followDriver"></label>
                                </div>
                            </div>
                        </div>
                        
                    </div>
              
                </div>

                {
                    behavior
                    &&
                    <Behaviour 
                        driver={driver} 
                        licenseplate={licenseplate}
                        branche={branche?.number}
                        date={createdAt}
                    />
                }
            
            </div>
        )
    }

    renderActivities = () => {
        const { data: { activity_status, preparation, status_history, stops, branche, departuretime, arrivaltime, gpsArrivaltime, gpsDeparturetime }, activities } = this.props;

        const prep = {
            activity_status: preparation, 
            customer_name: branche?.name + ' (Voorbereiden)',
            status_history: [],
            expected_arival: (departuretime ? 'Geplande vertrektijd: ' + departuretime.slice(0, 2) + ':' + departuretime.slice(2, 4) : ''),
            gpsDeparturetime: gpsDeparturetime
        }

        const todock = {
            activity_status: activity_status, 
            customer_name: branche?.name + ' (Terug naar dock)',
            status_history: (status_history ? status_history : []),
            expected_arival: (arrivaltime ? 'Gepland aankomsttijd: ' + arrivaltime.slice(0, 2) + ':' + arrivaltime.slice(2, 4) : ''),
            gpsArrivaltime: gpsArrivaltime
        }

        return (
            <div className="card" style={{ flex: 1, overflow: 'hidden' }}>
                <Tabs>

                    <Tab label="Adressen">
                        <div className="activities">
                            <Activity data={prep} next_activity={activities[0]} focusMarker={this.focusMarker} />
                            {
                                activities.map((activity, index) => {
                                    let next_activity = activities[(index+1)];

                                    let activityStops = [];
                                   
                                    if (Array.isArray(stops)) {
                                        stops.forEach((stop) => {

                                            const ac = stop.activities.find((a) => {
                                                return a.id === activity.documentId;
                                            });

                                            if (ac) {
                                                activityStops.push({
                                                    ...ac,
                                                    timestamp: stop.timestamp,
                                                })
                                            }

                                        });
            
                                    }

                                    if (activities.length === (index+1)) {
                                        next_activity = todock;
                                    }
                                    return <Activity key={activity.documentId} data={activity} next_activity={next_activity} activityStops={activityStops} focusMarker={this.focusMarker} />;
                                })
                            } 
                            <Activity data={todock} focusMarker={this.focusMarker} />
                        </div>
                    </Tab>

                    <Tab label="Stops">
                        { /* <CrossynStopList coordinates={crossyn_coordinates} map={this.map} activities={activities} /> */ }
                        <StopList stops={stops} getMap={this.getMap} activities={activities} />
                    </Tab>

                </Tabs>
         
            </div>
        )
    }

    renderMap = () => {
        const { followDriver } = this.state;

        const { data: { route_id, branche, coordinates, extra_data, activity_status, status_history, createdAt, crossyn_coordinates, stops, stops2, licenseplate, gpsDeparturetime, crossyn_stream_coordinates=[] }, activities, vehicle, behavior } = this.props;

        const now = new Date(createdAt);
        let enddate = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59);

        if (activity_status === 'done' || activity_status === 'todock_arrived') {
  
            const done = status_history.find((s) => {
                return s.status === 'done' || s.status === 'todock_arrived';
            });
            if (done) {
                //enddate = done.timestamp;
            }
        }
    
        /*
        let path = coordinates.map(({ latitude, longitude }) => {
            return {lat: latitude, lng: longitude}
        });
        */


        // Determine bounds
        let bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(branche?.latitude, branche?.longitude));
        activities.forEach(({ customer_latitude, customer_longitude }) => {
            if (customer_latitude && customer_longitude) {
                bounds.extend(new window.google.maps.LatLng(customer_latitude, customer_longitude));
            }
            
        });

        if (crossyn_coordinates) {
            crossyn_coordinates.map(({ latitude, longitude }, index) => {
                bounds.extend(new window.google.maps.LatLng(latitude, longitude));
            });
        }


        return (
            <Map
                ref={(map) => { this.map = map; }}
                zoom={12}
                panX={-200}
                center={{
                    latitude: (vehicle && vehicle.latitude ? vehicle.latitude : branche?.latitude),
                    longitude: (vehicle && vehicle.longitude ? vehicle.longitude : branche?.latitude)
                }}
                updateCenter={followDriver}
                streetViewControl={true}
                fitBounds={bounds}
                onStreetView={(visible) => {
                    if (visible) {
                        ReactGA.event({
                            category: 'Map',
                            action: 'View streetview'
                        });
                    }
                }}
            >

                <Marker
                    key={route_id}
                    position={{
                        latitude: branche?.latitude,
                        longitude: branche?.longitude
                    }}
                    icon={{
                        path: 'M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z',
                        fillColor: '#57004e'
                    }}
                    infoWindowContent={(
                        <div className='infowindow'><span className='name'>{ branche?.name }</span></div>
                    )}
                />
                
                {

                    activities.map(({ customer_number, customer_name, customer_latitude, customer_longitude, customer_second_name='', customer_street_number, customer_postalcode, customer_city, activity_status, activity_id, expected_arival='Tussen 08:00 en 11:00', invoice_number, is_manual=true, sequence, stops, pickup_coordinates }, index) => {
                        if (customer_number === '') { return null; }

                        let activity_latitude = 0;
                        let activity_longitude = 0;
                        
                        if (pickup_coordinates?.coordinates) {
                            activity_latitude = pickup_coordinates?.coordinates[1];
                            activity_longitude = pickup_coordinates?.coordinates[0];
                        } else if (customer_latitude && customer_longitude) {
                            activity_latitude = customer_latitude;
                            activity_longitude = customer_longitude;
                        }

                        if (activity_latitude === 0 && activity_longitude === 0) { return null; }

                        let color = '';
                        let label = sequence;
                        let label_color = '#ffffff';
                        switch(activity_status) {

                            case 'driving_paused':
                            case 'driving_resumed':
                            case 'driving':
                            case 'arrived_paused':
                            case 'arrived_resumed':
                            case 'arrived':
                                color = '#eaa14a';
                                label_color = '#343434';
                                break;
                            
                            case 'done':
                                color = '#a0c876';
                                label_color = '#343434';
                                break;
                            
                            case 'new':
                            default:
                                color = '#57004e';
                        }

                        let gps_stop = null;
                        if (stops) {
                            stops.forEach((stop) => {
                                if (gps_stop === null || gps_stop.distance > stop.distance) {
                                    gps_stop = stop;
                                } 
                            });
                        }

                        return (
                            <React.Fragment key={invoice_number}>
                                <Marker
                                    ref={(marker) => { this.markers[activity_id] = marker; }}
                                    position={{
                                        latitude: activity_latitude,
                                        longitude: activity_longitude
                                    }}
                                    icon={{ fillColor: color }}
                                    infoWindowContent={
                                        <div className='infowindow'>
                                            <span className='name'>{(sequence && sequence !== 999 ? sequence + '. ' : '')} { customer_name }</span><br />
                                            {
                                                customer_second_name
                                                &&
                                                <><span className='second_name'>{ customer_second_name }</span><br /><br /></>
                                            }
                                            <span className='line'>Venster: <span className='bold'>{ expected_arival }</span></span><br /><br />

                                            <span className='line'>Klantnr: <span className='bold'>{ customer_number }</span></span><br />
                                            <span className='line'>Factuurnummer: <span className='bold'>{ invoice_number }</span></span><br /><br />

                                            { is_manual && <><span className='line'>Handmatig ingevoerd</span><br /><br /></>}
                                            
                                            { customer_street_number }<br />
                                            { customer_postalcode } { customer_city }
                                        </div>
                                    }
                                    label={label}
                                    label_color={label_color}
                                />
                            </React.Fragment>
                        )

                    })
                } 

                {  /* <Polyline path={path} strokeOpacity={0.1}/> */ }

                { licenseplate && <CrossynPath licenseplate={licenseplate} startdate={createdAt} enddate={enddate} behavior={behavior} /> }
                
                { licenseplate && <CrossynVehicle licenseplate={licenseplate} type={(branche?.truck ? branche.truck : 'normal')} /> }

                <Stops stops={stops} activities={activities} />
    
                {
               
                    Array.isArray(crossyn_stream_coordinates)
                    &&
                    <CrossynCoordinates crossyn_stream_coordinates={crossyn_stream_coordinates} />  
                }
                
            </Map>
        );
    }

    onBack = () => {
        const { history, data: { branche } } = this.props;
        history.push('/groothandels/' + branche.documentId);
    }

    getMap = () => {
        return this.map;
    }

    render() {
        const { error, loading, data } = this.props;

        if (loading) { return <LoadingState label="Bezig met laden..." />; }
        if (error) { return <ErrorState label="Er is een fout opgetreden" message={error.message} />; }
        
        if (!Object.keys(data).length) { return null; }

        return (
            <Page onBack={this.onBack}>
                <div style={ {position: 'relative', height:'100%'} }>
                    
                    <div className="sidebar">
                        { this.renderInfo() }
                        { this.renderActivities() }
                    </div>
                    
                    { this.renderMap() }
                    
                </div>
            </Page>
        );

    }
}

function mapStateToProps(state, props) {

    const { data, error, loading } = state.routes.current;
    const { data: vehicles } = state.vehicles;

    const { user } = state.user;

	return {
        data: data,
        activities: getSortedActivities(state),
        error: error,
        loading: loading,
        vehicle: (data?.licenseplate ? vehicles[convertLicenseplate(data.licenseplate)] : null),
        behavior: (user?.behavior === true)
	};
}

export default connect(mapStateToProps)(Details);