import Dashboard from '../../../lib/api/Dashboard';


export function routeRequest() {
	return {
		type: 'ROUTE_REQUEST'
	};
}

export function routeSuccess(data) {


	// TODO: Remove Json = string workaround
	data.data.status_history = JSON.parse(data.data.status_history);
	data.data.extra_data = JSON.parse(data.data.extra_data);
	data.data.crossyn_coordinates = JSON.parse(data.data.crossyn_coordinates);
	data.data.stops = JSON.parse(data.data.stops);
	data.data.stops2 = JSON.parse(data.data.stops2);
	data.data.crossyn_stream_coordinates = JSON.parse(data.data.crossyn_stream_coordinates);

	if (data.data.activities) {
		data.data.activities = data.data.activities.map((activity) => {

			let ptv2 = null;
			if (!(typeof activity.ptv === 'object')) {
				ptv2 = JSON.parse(activity.ptv);
			}

			return {
				...activity,
				pickup_coordinates: JSON.parse(activity.pickup_coordinates),
				status_history: JSON.parse(activity.status_history),
				retour_packing: JSON.parse(activity.retour_packing),
				deliver_packing: JSON.parse(activity.deliver_packing),
				ptv: ptv2
			}
		});
	}
			
	return {
		type: 'ROUTE_SUCCESS',
		data: data,
		lastUpdate: Date.now()
	};
}

export function routeError(error) {
	return {
		type: 'ROUTE_ERROR',
		error: error
	};
}

export function updateRoute(data) {

	// TODO: Remove Json = string workaround
	data.data.status_history = JSON.parse(data.data.status_history);
	data.data.extra_data = JSON.parse(data.data.extra_data);
	data.data.crossyn_coordinates = JSON.parse(data.data.crossyn_coordinates);
	data.data.stops = JSON.parse(data.data.stops);
	data.data.stops2 = JSON.parse(data.data.stops2);
	data.data.crossyn_stream_coordinates = JSON.parse(data.data.crossyn_stream_coordinates);

	if (data.data.activities) {
		data.data.activities = data.data.activities.map((activity) => {

			let ptv2 = null;
			if (!(typeof activity.ptv === 'object')) {
				ptv2 = JSON.parse(activity.ptv);
			}

			return {
				...activity,
				pickup_coordinates: JSON.parse(activity.pickup_coordinates),
				status_history: JSON.parse(activity.status_history),
				retour_packing: JSON.parse(activity.retour_packing),
				deliver_packing: JSON.parse(activity.deliver_packing),
				ptv: ptv2
			}
		});
	}

	return {
		type: 'ROUTE_UPDATE',
		data: data,
	};
}

export function updateRouteActivity(data) {

	// TODO: Remove Json = string workaround
	data.data.pickup_coordinates = JSON.parse(data.data.pickup_coordinates);
	data.data.status_history = JSON.parse(data.data.status_history);
	data.data.retour_packing = JSON.parse(data.data.retour_packing);
	data.data.deliver_packing = JSON.parse(data.data.deliver_packing);
	
	let ptv2 = null;
	if (!(typeof data.data.ptv === 'object')) {
		ptv2 = JSON.parse(data.data.ptv);
	}

	data.data.ptv = ptv2;
	
	return {
		type: 'ROUTE_ACTIVITY_UPDATE',
		data: data,
	};
}

export function updateRouteTrips(data) {
	return {
		type: 'ROUTE_TRIPS_UPDATE',
		data: data,
	};
}


/* Async actions */

export function getRoute(id) {

	return function(dispatch, getState) {

		const state = getState();

		if (state.routes.current.loading) {
			return Promise.resolve();
		}
		
		dispatch(routeRequest());

		return Dashboard.getRoute(id).then(
			data => dispatch(routeSuccess(data)),
			error => dispatch(routeError(error))
		);
	}
}


export function getTrips(licenseplate, startdate, enddate) {

	return function(dispatch, getState) {
		return Dashboard.getTrips(licenseplate, { startdate: startdate, enddate: enddate, filter_method: 0, limit: 1000 }).then(
			data => dispatch(updateRouteTrips(data)),
			error => { return { error: error } }
		);
	}
}
