import React, { Component } from 'react';

import { useState } from 'react';

import Marker from '../../../../components/maps/Marker';

import './CrossynCoordinates.css';

const CrossynCoordinates = ({ crossyn_stream_coordinates=[] }) => {

    const [ show, setShow ] = useState(false);

    return (
        <>
            <button className="crossyn-data" onClick={() => { setShow(!show) }}>Crossyn data</button>
            
            {

                show
                &&
                crossyn_stream_coordinates.map((coord, index) => {
                    const { latitude, longitude, duration, time, duration2=0, speed } = coord || {};
                
      
                    if (speed > 10) {
                        //return null; 
                    }

                    return (
                        <Marker
                            key={'c2'+time+'-'+latitude+'-'+longitude}
                            icon={{ fillColor: '#29235c', scale: (duration2 < 60000 ? 0.6 : 1.4) }}
                            position={ {
                                latitude: latitude,
                                longitude: longitude
                            }}
                            zIndex={9999}
                            hideZoomLevel={16}
                            infoWindowContent={
                                <div className='infowindow'>
                                    <span style={{fontWeight:'bold'}}>stoped:</span> { time } <br/>
                                    <span style={{fontWeight:'bold'}}>duration2:</span> { (duration2/1000/60).toFixed(2) } Minuts<br/>
                                    <span style={{fontWeight:'bold'}}>speed:</span> { speed } <br/>
                                </div>
                            }
                        />
                    );
                })

            }
        </>
    );
}

export default CrossynCoordinates;