import React, { Component } from 'react';

import { connect }          from 'react-redux';

import Truck                from '../markers/Truck';

import Marker               from '../Marker';

import {
    convertLicenseplate,
    msToTime
}                           from '../../../lib/utils/Utils';


class RouteLayer extends Component {

    componentWillUnmount() {

        if (this.dashboard_route_updated) {
            this.dashboard_route_updated.remove();
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        
        const { latitude, longitude } = this.props;

        if (nextProps.latitude === latitude && nextProps.longitude === longitude) {
            return false;
        }

        return true;
    }

    render() {
        const { route, onClick, stops, latitude, longitude, rotation } = this.props;

        if (!route || !latitude || !longitude) { return null; }
        
        // Hide trucks from routes that are finished
        //if (route.status === 'done') { return null; }
        
        const { number, branche, extra_data, crossyn_coordinates, driver_name, licenseplate } = route;

        return (
            <>
            <Truck
                position={{
                    latitude: latitude,
                    longitude: longitude
                }}
                rotation={rotation}
                color={(branche ? branche.color : '#57004e')}
                infoWindowContent={
                    <div className='infowindow'>
                        { number !== null ? <div className='heading'>Route { number }</div> : null }
                        { driver_name !== null ? <div className='name'>{ driver_name }</div> : null }
                        { licenseplate !== null ? <div className='name'>{ licenseplate }</div> : null }
                    
                        { onClick !== null ? <button type="button" onClick={onClick}>Bekijk route</button> : null }
                    </div>
                }
                type={(branche?.truck ? branche.truck : 'normal')} // TODO: Fix truck 
            />

            {
                (stops && crossyn_coordinates)
                &&
                crossyn_coordinates.map((coord, index) => {
                    const { latitude, longitude, timestamp, duration } = coord;

                    let icon = {
                        url: '/images/stop-icon.png',
                        scaledSize: new window.google.maps.Size(16, 16),
                        anchor: {x: 8, y: 8}
                    }

                    if (duration < 2700000) {
                        icon = {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            fillColor: 'blue',
                            fillOpacity: 0.2,
                            strokeWeight: 0,
                            scale: 4,
                            anchor: {x: 0, y: 0}
                        }

                        return null;
                    }

                    return (    
                        <Marker
                            key={'d'+index}
                            icon={icon}
                            position={ {
                                latitude: latitude,
                                longitude: longitude
                            }}
                            zIndex={9999}
    
                            infoWindowContent={
                                <div className='infowindow'>
                                    
                                    <b>Route:</b> { number } <br/>
                                    <b>Nummerboord:</b> { licenseplate } <br/><br/>
                                    <b>Aangekomen:</b> { new Date(timestamp).toLocaleTimeString() } <br/>
                                    <b>Stilstand:</b> { msToTime(duration) } Min. <br/>
                                    <br/>
                                    latitude: { latitude } <br/>
                                    longitude: { longitude } <br/><br/>
                                    {onClick !== null ? <button type="button" onClick={onClick}>Bekijk route</button> : null}
                                </div>
                            }
                        />
                    )
                })
            }
            </>
        );
    }

}

RouteLayer.defaultProps = {
    route_id: '',
    route: null,
    onClick: null,
    stops: false
};

function mapStateToProps(state, props) {
    
    const route = state.routes.all.data[props.route_id];

    const vehicle = state.vehicles.data[convertLicenseplate(route.licenseplate)];

	return {
        route: (route ? route : null),
        latitude: (vehicle ? vehicle.latitude : null),
        longitude: (vehicle ? vehicle.longitude : null),
        rotation: (vehicle ? vehicle.rotation : null)
	};
}

export default connect(mapStateToProps)(RouteLayer);
